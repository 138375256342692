<template>
  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card
            color="success"
            icon="mdi-database"
            :title="baseCardTitle"
            inline
        >
          <v-row>
            <v-col cols="10"></v-col>
            <v-col cols="2" class="text-right">
              <v-btn small right color="info" class="mr-0" @click="goToPage({route: 'Resources'})">
                <v-icon small class="mr-1">
                  mdi-arrow-left
                </v-icon>
                Back
              </v-btn>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col cols="12" md="12">
              <template v-if="loading">
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
              </template>
              <template v-else>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Name *"
                        :rules="[() => !!database.name || 'This field is required!']"
                        v-model="database.name"
                        counter
                        required
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        :items="clusters"
                        v-model="database.cluster"
                        item-text="name"
                        item-value="name"
                        label="Cluster *"
                    ></v-select>
                  </v-col>
                  <v-col cols="6" xs="12" md="6">
                    <v-switch
                        label="Multitenant *"
                        v-model="database.multitenant"
                        inset
                        required
                    />
                  </v-col>
                  <v-col cols="6" xs="12" md="6">
                    <v-switch
                        label="Locked *"
                        v-model="database.locked"
                        inset
                        required
                    />
                  </v-col>

                </v-row>
                <v-row>
                  <v-col cols="12" md="12" class="text-right pr-0 mr-0">
                    <v-btn
                        color="success"
                        v-if="canAccess({route: '/environments/:env/databases', method: 'put'}) || canAccess({route: '/environments/:env/databases/:id', method: 'patch'})"
                        @click="saveDatabase">
                      <v-icon class="mr-1">mdi-content-save</v-icon>
                      {{ this.id && this.id.trim() !== '' ? 'Save' : 'Create' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import globalMixins from "@/mixins/globalMixins";
import fieldsMixins from "./components/fieldAccess";

export default {
  name: 'addEditDatabase',

  props: {
    envSelected: {
      type: Object
    },
    id: {
      type: String
    }
  },

  mixins: [globalMixins, fieldsMixins],

  data() {
    return {
      fieldAccess: {},
      clusters: [],
      loading: true,
      database: {
        name: '',
        multitenant: false,
        locked: false,
        cluster: ''
      }
    }
  },

  computed: {
    baseCardTitle() {
      if (this.id && this.database.name) {
        return 'Updating Database ' + this.database.name;
      } else {
        return 'Creating New Database';
      }
    }
  },

  created() {
    const _self = this;
    setTimeout(() => {
      _self.initialize();
    }, 1000)
  },

  methods: {

    initialize() {
      this.loading = true;
      let apiOptions = {
        url: `/consoleapi/environments/${this.envSelected.value}/data-clusters`,
        method: "get",
        params: {
          pagination: false,
          fields: ['name', 'author']
        }
      };

      this.getSendData(apiOptions).then((response) => {
        this.clusters = response.items;

        if (this.id && this.id.trim() !== '') {
          // edit mode
          // this.fieldAccess = this.databaseUpdateAccess();
          let apiOptions = {
            url: `/consoleapi/environments/${this.envSelected.value}/databases`,
            method: "get"
          };

          this.getSendData(apiOptions).then((registry) => {
            if (registry && registry.items) {
              registry.items.forEach((oneDB) => {
                if (oneDB.name === this.id) {
                  this.database.name = oneDB.name;
                  this.database.cluster = oneDB.cluster;
                  this.database.multitenant = oneDB.multitenant;
                  this.database.locked = oneDB.locked;
                }
              });
            }
            this.loading = false;
          });
        } else {
          // create mode
          // this.fieldAccess = this.databaseCreateAccess();
          this.loading = false;
        }
      });
    },

    checkBasic(database) {
      this.clearMessages();
      if (!database.name || database.name.trim() === '') {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'Field Name is required!'
        });
        return false;
      }

      if (!database.cluster || database.cluster.trim() === '') {
        this.pushMessage({
          type: 'error',
          title: 'Validation Error',
          text: 'Field Cluster is required!'
        });
        return false;
      }

      return true;
    },

    saveDatabase() {
      if (!this.checkBasic(this.database)) {
        return false;
      }

      let payload = this._lodash.cloneDeep(this.database);
      let apiOptions;

      if (this.id && this.id.trim() !== '') {
        //this.filterFields(payload, this.fieldAccess);
        apiOptions = {
          url: `/consoleapi/environments/${this.envSelected.value}/databases/${this.id}`,
          method: "patch",
          params: payload
        };
      } else {
        apiOptions = {
          url: `/consoleapi/environments/${this.envSelected.value}/databases`,
          method: "put",
          params: payload
        };
      }

      this.getSendData(apiOptions).then(() => {
        let label = (this.id && this.id.trim() !== '') ? 'updated' : 'created';
        this.pushMessage({
          type: 'success',
          title: `Database ${label}`,
          text: `The database ${payload.name} has been ${label}!.`
        });
        this.scrollToTop();

        setTimeout(() => {
          this.goToPage({route: 'Resources'});
        }, 2000);
      });
    }
  }
};

</script>